import { all, put, call, takeLatest } from "redux-saga/effects";

import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import type { InitWorkflowEditorPayload } from "ee/actions/workflowActions";
import WorkflowEditorEngine from "ee/entities/Engine/WorkflowEditorEngine";
import { resetEditorRequest } from "actions/initActions";
import { setDataUrl } from "./PageSagas";

export function* startWorkflowEngine(
  action: ReduxAction<InitWorkflowEditorPayload>,
) {
  try {
    const workflowEngine = new WorkflowEditorEngine();
    const { workflowId } = action.payload;

    /**
     * During editor switches like app (view mode) -> workflow
     * there are certain cases were stale data stays in reducers.
     * This ensures a clean state of reducers and avoids any dependency
     */
    yield put(resetEditorRequest());
    yield call(workflowEngine.setupEngine);
    yield call(workflowEngine.loadWorkflow, workflowId);
    // Calling LINT setup before actions are fetched to allow feature flags etc to be
    // set up before first lint happens
    yield put({ type: ReduxActionTypes.LINT_SETUP });
    // used to set the current URL and pathname to the appsmith object
    // This is to allow workers to find out the editor type (since workers can't access the URL)
    yield call(setDataUrl);
    // TODO (Workflows): Lot of these apis require separate workflow apis without dependance on pageId or applicationId
    yield call(workflowEngine.loadPageThemesAndActions, workflowId);
    yield call(workflowEngine.loadPluginsAndDatasources);
    yield call(workflowEngine.completeChore);
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.INITIALIZE_WORKFLOW_EDITOR_ERROR,
      payload: {
        error,
      },
    });
  }
}

export default function* watchWorkflowInitSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.INITIALIZE_WORKFLOW_EDITOR,
      startWorkflowEngine,
    ),
  ]);
}
